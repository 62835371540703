// SPDX-FileCopyrightText: 2024 Thomas Breitner <t.breitner@csl.mpg.de>
//
// SPDX-License-Identifier: EUPL-1.2

// main.js

import './main.scss';
// import all of bootstrap
import * as bootstrap from 'bootstrap';
import TomSelect from 'tom-select';
import * as tocbot from 'tocbot';

window.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll('.is-tom-select').forEach((el)=>{
        let tomSelectconfig = {
            plugins: ['remove_button'],
            create: false,
        };
        new TomSelect(el, tomSelectconfig);
    });
});


tocbot.init({
    // Where to render the table of contents.
    tocSelector: '.toc',
    // Where to grab the headings to build the table of contents.
    contentSelector: '#article-main',
    // Which headings to grab inside of the contentSelector element.
    headingSelector: 'h1, h2, h3, h4',
    collapseDepth: 6,
});


// import Sticky from 'sticky-js';
// var Sticky = require('sticky-js');
// var sticky = new Sticky('#countries_scrollspy');
// import 'sticky-js'
// const sticky = new Sticky('#countries_scrollspy');
